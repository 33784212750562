import React, { lazy } from 'react'
import { useRoutes } from 'react-router-dom'
import FourOhFour from '../pages/FourOhFour'
import { retry } from '../common/commonFunctions'

const NavBar = lazy(() => retry(() => import('../layout/NavBar.jsx')))

const Routes = () => {
  const routes = useRoutes([
    {
      path: '/*',
      element: <NavBar />,
    },
  ])
  return routes
}

export default Routes
