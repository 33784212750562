import React, { Suspense } from 'react'
import ThemeProvider from './theme'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import ScrollToTop from './components/ScrollToTop'
import Routes from './routes/Routes'
import { styled } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import Loader from './components/Loader'

// alert notification
const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.notistack-MuiContent-success {
    background-color: #ac9559;
    color: #ffffff;
  }
  ,
  &.notistack-MuiContent-error {
    background-color: #ff1e1e;
  }
  ,
  &.notistack-MuiContent {
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
      0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    font-weight: 500;
    border-radius: 12px;
  }
`

const App = () => {
  return (
    <>
      <BrowserRouter>
        <HelmetProvider>
          <ThemeProvider>
            <ScrollToTop />
            <StyledSnackbarProvider
              hideIconVariant
              autoHideDuration={3000}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Suspense fallback={<Loader />}>
                <Routes />
              </Suspense>
            </StyledSnackbarProvider>
          </ThemeProvider>
        </HelmetProvider>
      </BrowserRouter>
    </>
  )
}

export default App
