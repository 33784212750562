import { Box, Button, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

const FourOhFour = () => {
  const navigate = useNavigate()

  return (
    <Fragment>
      <Helmet>
        <title>404 | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          inset: 0,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '100vh',
          bgcolor: 'secondary.main',
        }}
      >
        <Typography
          variant="h1"
          sx={{ fontSize: { md: '300px', xs: '60px', sm: '120px' } }}
          color="primary"
        >
          404
        </Typography>
        <Typography variant="h4" color="common.white">
          Something went wrong
        </Typography>
        <Typography
          variant="body1"
          sx={{ mt: 1.5, textAlign: 'center', fontWeight: 500 }}
          color="common.white"
        >
          The page you are looking for was moved, removed, renamed or might
          never existed!
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 4 }}
          onClick={() => {
            navigate('/')
          }}
          color="primary"
        >
          Go To Home
        </Button>
      </Box>
    </Fragment>
  )
}

export default FourOhFour
