import { CircularProgress, Stack } from '@mui/material'
import React from 'react'

const Loader = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height="100%"
      bgcolor="secondary.main"
    >
      <CircularProgress color="primary" />
    </Stack>
  )
}

export default Loader
