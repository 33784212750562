import PropTypes from 'prop-types'
import { useMemo } from 'react'
// @mui
import { CssBaseline, responsiveFontSizes } from '@mui/material'
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles'
//
import palette from './palette'
import shadows from './shadows'
import typography from './typography'
import GlobalStyles from './globalStyles'
import customShadows from './customShadows'

// ----------------------------------------------------------------------

const ThemeProvider = (props) => {
  const { children } = props

  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [],
  )

  const theme = createTheme(themeOptions)
  theme.components = {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontSize: 16,
          padding: '8px 16px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          opacity: 0.5,
          '&.Mui-selected': {
            opacity: 1,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
          color: theme.palette.grey[800],
          marginBottom: '4px',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: theme.palette.common.black,
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #F3F1EC inset',
            WebkitTextFillColor: '#000000',
          },
        },
      },
    },
  }

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
}

export default ThemeProvider
